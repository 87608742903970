import SendIcon from '@mui/icons-material/Send';
import { Avatar, IconButton, Input } from '@mui/material';
import { format } from 'date-fns';
import './styles.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate  } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import { FAQPage } from '../../../pages/duvidas';
import avatarPng from '../../../../public/assets/avatar.png';
import { FAQList } from '../../../pages/FAQ/questions';
import { RootState } from '../../../redux/stores';
import socket from '../../../services/socket';
import { ChatWindowContainer, ChatBotContainer } from './styles';
import { currentChatAction, handleChatAction } from '../../../redux/actions/index.action';
import { emitWarning } from 'process';
import { getNoticias } from '../../../services/api';
import { useQuery } from 'react-query';
import { FaRegNewspaper } from "react-icons/fa6";
import { FaFilePdf } from "react-icons/fa6";
import ReactQuill from 'react-quill';
import usuarioPng from '../../../../public/assets/userr.png';
import {  synonymsData as synonyms  } from '../../synonyms';



interface ChatBotProps {
  handleSupport: () => void;
  onclick: () => void;


}



interface NoticiaItem {
  id: number;
  imageURL: string;
  titulo: string;
  data: string;
  conteudo: string;
}

export function ChatBot({ handleSupport, onclick }: ChatBotProps) {
  const { question } = useSelector((state: any) => state.questionReducer);
  const [currentMessage, setCurrentMessage] = useState(question);
  const [option, setOption] = useState('');
  const [showMessageContainer, setShowMessageContainer] = useState(false);
  const [showNovasOpcoes, setShowNovasOpcoes] = useState(false);
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hoveredd, setHoveredd] = useState(false);
  const [visible, setVisible] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null); // Definindo a referência do input
  const [selectedNoticia, setSelectedNoticia] = useState<NoticiaItem | null>(null);
  
  const dispatch = useDispatch();


  const { data: noticias } = useQuery('noticias', async () => {
    const response = await getNoticias();
    const data = response?.data;

    if (data) {
      data.sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime());
    }

    return data;
  }, {
    refetchOnWindowFocus: false,
  });


  //teste

  const { user } = useSelector((state: RootState) => state.userReducer);

  const navigate = useNavigate();

  let messagesEnd: any;

  const room = user.usuario;


  const openPopup = (noticia: NoticiaItem) => {
    setSelectedNoticia(noticia);
  };

  const closePopup = () => {
    setSelectedNoticia(null);
    window.scrollTo({
      top: 1 ,
      behavior: 'smooth'
    });
  };
  const scrollToBottom = () => {
    messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };

  const sendMessage = () => {
    if (currentMessage !== '') {
      const messageData = {
        room,
        author: user.nome,
        message: currentMessage,
        time: `${new Date(Date.now()).getHours()}:${new Date(Date.now()).getMinutes()}:${new Date(
          Date.now()
        ).getMinutes()}`,
      };

      socket.emit('private_message', messageData);
      setCurrentMessage('');
    }
  };

  useEffect(() => {
    if (option === 'suporte') {
      handleSupport();
    }
  }, [option]);




  const limparInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };


  

 


  const filterTopics = (searchText: string) => {
    const searchWords = searchText
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/g, "")
      .split(/\s+/);

    const filteredTopics = FAQList.chatbot.filter(topic =>
      searchWords.some(word => {
        // Verifica se a palavra digitada está presente nos sinônimos
        const synonymsForWord = Object.entries(synonyms).find(([key, value]) => value.includes(word));
        // Se encontrarmos uma palavra que seja sinônimo, usamos a palavra original
        const originalWord = synonymsForWord ? synonymsForWord[0] : word;
        // Verifica se a palavra original ou seus sinônimos estão presentes no tópico
        return synonyms[originalWord]?.some(synonym =>
          topic.msg
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[^\w\s]/g, "")
            .includes(synonym)
        );
      })
    );

    return filteredTopics;
  };

  const filterTopicsNews = (searchText, noticias) => {
    const searchWords = searchText
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/g, "")
      .split(/\s+/);
  
    const filteredTopicsNews = noticias.filter(noticia =>
      searchWords.some(word =>
        noticia.conteudo
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^\w\s]/g, "")
          .includes(word)
      )
    );
  
    return filteredTopicsNews;
  };
  


  const opçoes = () => {
    setShowNovasOpcoes(false);
    setShowNotFoundMessage(false);
    limparInput();

  }




  const gotoPDFPage = (pdfID: string) => {
    window.open(`/pdf/${pdfID}`);
  };




  return (

    <ChatWindowContainer style={{ display: visible ? 'block' : 'none' }}>

      <div className="title-container">Suporte Mercadótica</div>
      <div className="chat-container">
        {option === '' && (
          <ChatBotContainer>
            <Avatar
              src={avatarPng}
              sx={{
                height: 50,
                width: 50,
                '&:hover': { border: '1px solid #fff' },
              }}
              style={{
                position: 'relative',
                boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',
                transition: 'all 0.5s ease',

              }}

            />
            <div className="message-container">

              <p>Olá,<strong>{user.nome}</strong>!!!Bem vindo,sou o Assistente virtual da Mercadótica.</p>
              <p>Para  ajudá-lo digite a sua dúvida ,em poucas palavras, no campo abaixo !😉 </p>

              <div className="options">

              </div>
            </div>



          </ChatBotContainer>
        )}
        <ChatBotContainer>


          <Avatar
          className='user-avatar'
             src={usuarioPng}
            sx={{
              height: 48,
              width: 48,
              '&:hover': { border: '1px solid #fff' },
            }}
            style={{
              position: 'relative',
              
              boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',
              transition: 'all 0.5s ease',
              cursor: "pointer"
            }}

            onClick={() => opçoes()}
            onMouseEnter={() => setHoveredd(true)}
            onMouseLeave={() => setHoveredd(false)}

          /> 
          
          {/* <div className="avatarHello" style={{
            opacity: hoveredd ? '1' : '0',
            transition: 'all 0.33s ease',
            marginTop: " -30px", marginLeft: "-51px",
            boxShadow: ' 0px 0px 16px 6px rgba(0, 0, 0, 0.33)', borderRadius: "10px",
            height: "10%", zIndex: "9999"
           }}>
            Limpar
          </div>
*/}
         


         <div className="message-container-">

          <input
          ref={inputRef}
          className='input-bot'
          style={{ border: '0.2px solid white', height: '35px', fontSize: '16px', borderRadius: '10px' }}
          type="text"
          placeholder="digite aqui..."
          onChange={(e) => setCurrentMessage(e.target.value)}
          value={currentMessage}
          onKeyUp={(e) => {
          if (e.key === 'Enter') {
          setShowNovasOpcoes(false);
          setShowNotFoundMessage(false);
          const inputText = currentMessage.trim();
          const filteredFAQs = filterTopics(inputText); // Filtrando a lista de FAQs
          const filteredNews = filterTopicsNews(inputText, noticias); // Filtrando a lista de notícias
          if (filteredFAQs.length > 0 || filteredNews.length > 0) {
          setShowNovasOpcoes(true);
          } else {
          setShowNotFoundMessage(true);
          }
          }
          }}
          />
         </div>
         </ChatBotContainer>

         <ChatBotContainer >

        

         <Avatar
              src={avatarPng}
              sx={{
                height: 50,
                width: 50,
                '&:hover': { border: '1px solid #fff' },
              }}
              style={{
                position: 'relative',
                boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',
                transition: 'all 0.5s ease',

              }}

               />
   
          <div className="message-container">
         <h5>...</h5>
            {showNovasOpcoes && (
              <div className="mensagens">
                <p><strong>Abaixo estão alguns tópicos que possam te interessar!</strong></p>
                {filterTopics(currentMessage.trim()).map((topic, index) => (
                <p
               key={index}
                role="button"
               onClick={() => {
                // Lógica para lidar com a seleção do tópico
               console.log(`Tópico selecionado: ${topic.msg}`);
                    }}
                onKeyUp={(e) => {
                if (e.key === 'Enter') {
                // Lógica para lidar com a seleção do tópico
                 console.log(`Tópico selecionado: ${topic.msg}`);
                }
                }}
                  >
                 <div className='options'>
                <p onClick={(): void => gotoPDFPage(topic.src)} key={index}>
                <FaFilePdf />  {topic.msg}
                 </p>
                </div>
                </p>
               ))}

               {filterTopicsNews(currentMessage.trim(), noticias).map((noticia, index) => (
  
              <p
               key={index + 'news'} // Adiciona um sufixo para diferenciar dos tópicos
               role="button"
               onClick={() => {
               // Lógica para lidar com a seleção da notícia
               console.log(`Notícia selecionada: ${noticia.id}`);
               }}
               onKeyUp={(e) => {
               if (e.key === 'Enter') {
               // Lógica para lidar com a seleção da notícia
               console.log(`Notícia selecionada: ${noticia.id}`);
               }
      
               }}
              >
  
             <div className='options'>
             <p onClick={(): void =>(noticia.src)} key={noticia.id}>
        
              <p  className='linkados' onClick={() => openPopup(noticia)}>  
              <FaRegNewspaper /> 
               {noticia.titulo} </p>
       
               </p>
               </div>
               </p>
               ))}

                <div className="options">
                </div>
                <h5> Ainda com dúvidas?</h5>
                <p className='duvida-sim' onClick={handleSupport}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >Sim </p> <div className="avatarHello" style={{
                  opacity: hovered ? '1' : '0',
                  transition: 'all 0.33s ease',
                  marginTop: " -30px", marginLeft: "37px",
                  boxShadow: ' 0px 0px 16px 6px rgba(0, 0, 0, 0.33)', borderRadius: "16px",
                  width: "65%", textAlign: "center"
                }}>
                  Falar com o suporte
                </div>
                <p className='duvida-nao' onClick={onclick} >Não, finalizar o chat</p>
               </div>
               )}

               {showNotFoundMessage && (
               <div className="not-found-message">
                <h5> Infelizmente Nenhum tópico relacionado foi encontrado!</h5>
                <div className='options'>

                  <p onClick={handleSupport}>Falar com suporte</p>
                  <p className='duvida-nao' onClick={onclick} >Finalizar o chat</p>
                </div>

               </div>
               )}
              </div>
             </ChatBotContainer>

           


        {selectedNoticia && (
          <div className='pop-up-containerr-'  >
            <div className="pop-upp-" >
              <div className="popup-contentt-" >
              <h2 className='titulo-popup-'>{selectedNoticia.titulo} </h2>
              {/*<h5 className='data-titulo-'>{format(new Date(selectedNoticia.data),'dd/MM/yyyy')}</h5>*/}  
                <img  className="img-popup-"src={selectedNoticia.imageURL}  />
                
                <ReactQuill
                className='texto-popup-'
                  style={{ height: '300px', width: '99.8%' }}
                  value={selectedNoticia.conteudo}
                  readOnly={true}
                  modules={{ toolbar: false }}
                />
              </div>
              <button onClick={closePopup}>Fechar</button>
            </div>
          </div>
          
        )}




        <div
          style={{ float: 'left', clear: 'both' }}
          ref={(el) => {
            messagesEnd = el;
          }}
        />
      </div>

      <div className="input-container">

      </div>

    </ChatWindowContainer>
    

  );


}
