import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { RoomItemComponent } from '../../../components/RoomItem';
import { MessageInterface } from '../../../interfaces/MessageInterface';
import { currentChatAction, roomsAction, saveMessageHistoryAction } from '../../../redux/actions/index.action';
import { RootState } from '../../../redux/stores';
import socket from '../../../services/socket';
import { MessageContainer, ChatFeed, ChatInput, MessageContent, RoomListContainer } from './styles';
import { FaImage, FaFilePdf, FaFileArchive, FaFileWord, FaFileExcel, FaFileDownload, FaPaperclip } from 'react-icons/fa';
import { IconButton, Input, LinearProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import imageCompression from 'browser-image-compression';
import { AiFillMessage } from "react-icons/ai";
import { MdOutlineTimer } from "react-icons/md";
import "./styles.css"

interface Room {
  name: string;
  unreadCount: number;
}

export function AdminChat() {
  const username = 'Suporte';
  const dispatch = useDispatch();

  const [messages, setMessages] = useState<MessageInterface[]>([]);
  const [visible, setVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [loadingImages, setLoadingImages] = useState<boolean>(false);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [userMessageHistory, setUserMessageHistory] = useState<{ [key: string]: MessageInterface[] }>({});
  const [loadedHistory, setLoadedHistory] = useState<boolean>(false);
  const [uploadFinal, setUploadFinal] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedmsg, setSelectedmsg] = useState(false);


  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { rooms } = useSelector((state: RootState) => state.roomsReducer);
  const { currentChat } = useSelector((state: RootState) => state.currentChatReducer);
  const { user } = useSelector((state: RootState) => state.userReducer);


  //tem como função deixar a conversa visivel ou nao quando esta fora da aba do chat
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setVisible(false); // Altera o estado para false quando a aba estiver oculta
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);




  useEffect(() => {
    const handleNewMessage = (data: any) => {
      if (data.author !== "Suporte") {
        setUnreadCount((prevUnreadCount) => prevUnreadCount + 1);
      }

    };

    socket.on('private_message', handleNewMessage);

    return () => {
      socket.off('private_message', handleNewMessage);
    };
  }, []);


  useEffect(() => {
    document.title = `(${unreadCount}) Intranet - Mercadotica`;
  }, [unreadCount]);

  useEffect(() => {
    let totalUnreadCount = 0;

    rooms.forEach(room => {
      if (room.messages) {
        Object.values(room.messages).forEach((message: any) => {
          if (message.author !== username) {
            totalUnreadCount++;
          }
        });
      }
    });

    setUnreadCount(totalUnreadCount);
  }, [rooms]);


  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages]);

  const sendMessage = () => {
    if (currentMessage !== '' || selectedImages.length > 0 || selectedFiles.length > 0) {
      const messageData = {
        room: currentChat,
        author: username,
        message: currentMessage,
        images: selectedImages,
        files: selectedFiles,
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      socket.emit('private_message', messageData);
      setCurrentMessage('');
      setSelectedImages([]);
      setSelectedFiles([]);
      setUploadProgress(0);
    }
  };


  //função para comprimir a imagem caso seja maior do que o aceitavel
  const compressImage = async (file: File): Promise<File> => {
    const options = {
      maxSizeMB: 0.7, // 700 KB
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      throw new Error('Erro ao comprimir a imagem: ');
    }
  };
  //verifica se a mensagem é uma imagem
  const checkIfImageFile = (fileType: string): boolean => {
    const imageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    return imageTypes.includes(fileType);
  };

  //verifica se é um arquivo e se o mesmo é do tamanho aceitavel
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      e.target.value = '';
      setUploadProgress(0);

      if (!checkIfImageFile(selectedFile.type)) {
        const maxSizeMB = 5;
        const fileSizeMB = selectedFile.size / (1024 * 1024);
        if (fileSizeMB > maxSizeMB) {
          alert('Por favor, envie um arquivo menor (limite de 5MB).');
          return;
        }
      }

      try {
        setLoadingFiles(true);

        let fileUrl = '';

        if (checkIfImageFile(selectedFile.type)) {
          const compressedFile = await compressImage(selectedFile);
          fileUrl = await uploadFile(compressedFile, (progress) => {
            setUploadProgress(progress);
          });
          setSelectedImages((prev) => [...prev, fileUrl]);
        } else {
          fileUrl = await uploadFile(selectedFile, (progress) => {
            setUploadProgress(progress);
          });
          setSelectedFiles((prev) => [...prev, fileUrl]);
        }

        setUploadFinal(true);
      } finally {
        setLoadingFiles(false);
      }
    }
  };

  useEffect(() => {
    if (uploadFinal) {
      sendMessage();
      setUploadFinal(false);
    }
  }, [uploadFinal]);

  //função para upar o arquivo
  const uploadFile = (file: File, onProgress: (progress: number) => void): Promise<string> => {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve(e.target?.result as string);
      };

      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        onProgress(progress);

        if (progress >= 100) {
          clearInterval(interval);
        }
      }, 500);

      setTimeout(() => {
        clearInterval(interval);
        reader.readAsDataURL(file);
      }, 2000);
    });
  };

  useEffect(() => {
    if (!loadedHistory && user && userMessageHistory[user.author]) {
      setMessages(userMessageHistory[user.author]);
      setLoadedHistory(true);
    }

    const handlePrivateMessage = (data: MessageInterface) => {
      if (data.room === currentChat) {
        setMessages((prev) => [...prev, data]);
      }

      setUserMessageHistory((prevHistory) => {
        const userHistory = prevHistory[data.author] || [];
        return {
          ...prevHistory,
          [data.author]: [...userHistory, data],
        };
      });
    };

    const handleMessagesInRoom = (data: MessageInterface[]) => {
      if (data) {
        setMessages(data);

        if (currentChat && userMessageHistory[currentChat]) {
          const currentUserHistory = userMessageHistory[currentChat].filter(
            (message) => message.author === username
          );

          setMessages((prevMessages) => [...prevMessages, ...currentUserHistory]);
        }
      }
    };

    socket.on('private_message', handlePrivateMessage);
    socket.on('messagesInRoom', handleMessagesInRoom);

    return () => {
      dispatch(saveMessageHistoryAction(userMessageHistory));
      socket.off('private_message', handlePrivateMessage);
      socket.off('messagesInRoom', handleMessagesInRoom);
    };
  }, [socket, currentChat, dispatch, user, userMessageHistory, loadedHistory, username]);

  //função para fechar a conversa
  const closeCall = () => {
    socket.emit('closeCall', { room: currentChat, userId: user.id });
    setVisible(false);
    dispatch(currentChatAction(''));
  };

  const joinRoom = (room: string) => {
    socket.emit('join', room);
    dispatch(currentChatAction(room));
    setVisible(true);

    if (userMessageHistory[room]) {
      setMessages(userMessageHistory[room]);
    } else {
      socket.emit('getMessagesInRoom', { room });
    }
  };


  const isImageFile = (file: string): boolean => {
    const imageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    return imageTypes.some((type) => file.startsWith(type));
  };



  //verificar e converter a mensagem em um link clicavel
  const convertUrlsToLinks = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>
          {part}
        </a>
      ) : (
        part
      )
    );
  };


  const messagesfinal = [
    "Agradecemos o seu contato! Qualquer outra dúvida, estamos à disposição.\n Tenha um excelente dia!",
    "Caso não tenhamos uma interação nos próximos 5 minutos, o atendimento será finalizado .\n Fique à vontade para retornar a qualquer momento.",
  ];

  // Função para copiar a mensagem selecionada para a área de transferência
  const copyToClipboard = (message) => {
    navigator.clipboard.writeText(message)
      .then(() => {
        setCurrentMessage(message); // Atualiza o campo de entrada com a mensagem copiada

      })
      .catch((err) => {
        console.error("Erro ao copiar: ", err);
      });
    setSelectedmsg(false);

  };

  const openPopupmsg = () => {
    setSelectedmsg(true);

  };



  //tratamento para manter estavel a conexao do servidor 
  useEffect(() => {
    let reconnectionInterval;

    const reconnectSocket = () => {
      if (!socket.connected) {
        console.log("Tentando reconectar ao servidor...");
        socket.connect();
      }
    };

    const handleConnection = () => {
      console.log("Conexão estabelecida com sucesso.");
      clearInterval(reconnectionInterval);
    };

    const handleDisconnection = () => {
      console.log("Conexão perdida. Tentando reconectar...");
      reconnectionInterval = setInterval(reconnectSocket, 5000); // Tentar reconectar a cada 5 segundos
    };

    // Função de heartbeat para monitorar a conexão
    const sendHeartbeat = () => {
      if (socket.connected) {
        console.log("Enviando heartbeat para manter a conexão ativa.");
        socket.emit('heartbeat', { userId: user.id });
      } else {
        console.log("Heartbeat não enviado: conexão perdida.");
      }
    };

    socket.on('connect', handleConnection);
    socket.on('disconnect', handleDisconnection);

    // Iniciar o intervalo de heartbeat para manter a conexão ativa
    const heartbeatInterval = setInterval(sendHeartbeat, 30000);

    return () => {
      clearInterval(reconnectionInterval);
      clearInterval(heartbeatInterval);
      socket.off('connect', handleConnection);
      socket.off('disconnect', handleDisconnection);
      console.log("Limpeza completa: desconectando e removendo eventos.");
    };
  }, [socket, user.id]);


  const openPopup = (imageUrl) => {
    setSelectedImage(imageUrl);
  };


  const closePopup = () => {
    setSelectedImage(null);
    window.scrollTo({
      top: 1,
      behavior: 'smooth',
    });
  };





  return (
    <AdminPageContainer display={visible ? 'flex' : 'block'} padding="0">
      <RoomListContainer>
        <h1>Chamados ativos</h1>
        {rooms &&
          rooms.map((room: Room, index: number) => (
            <RoomItemComponent key={index} room={room} onClick={() => joinRoom(room.name)} />
          ))}
      </RoomListContainer>
      {visible && (
        <MessageContainer>
          <ChatFeed>
            <button className="finalizar" type="button" style={{ marginTop: '-10px' }} onClick={closeCall}>
              Finalizar atendimento
            </button>
            <div style={{ marginTop: '3rem' }}>
              {messages &&
                messages.map((message, index) => (
                  <MessageContent key={index} author={username === message.author}>
                    <div className="message">

                      <p>{convertUrlsToLinks(message.message)}</p>
                      <p style={{ fontSize: '13px', color: 'black' }}> {message.time}</p>
                      {message.images &&
                        message.images.map((image, imageIndex) => (
                          <img
                            key={imageIndex}
                            src={image}
                            alt={`Imagem ${imageIndex + 1}`}
                            style={{
                              maxWidth: '100%',
                              maxHeight: '200px',
                              borderRadius: '8px',
                              marginTop: '8px',
                              cursor: "pointer",
                            }}
                            onClick={() => openPopup(image)}
                          />

                        ))}
                      {message.files &&
                        message.files.map((file, fileIndex) => (
                          <div key={fileIndex}>
                            <a
                              href={file}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ marginLeft: '0%', fontSize: '45px', color: 'grey' }}
                            >
                              {file.endsWith('.pdf') && <FaFilePdf />}
                              {file.endsWith('.rar') && <FaFileArchive />}
                              {file.endsWith('.doc') && <FaFileWord />}
                              {file.endsWith('.docx') && <FaFileWord />}
                              {file.endsWith('.xls') && <FaFileExcel />}
                              {file.endsWith('.xlsx') && <FaFileExcel />}
                              {file.endsWith('.png') && <FaFileExcel />}
                              {!file.endsWith('.pdf') &&
                                !file.endsWith('.rar') &&
                                !file.endsWith('.doc') &&
                                !file.endsWith('.docx') &&
                                !file.endsWith('.xls') &&
                                !file.endsWith('.xlsx') && <FaFileDownload />}
                            </a>
                          </div>
                        ))}
                    </div>
                    {selectedImage && (
                      <div className='pop-up-container-img-modal'
                        onClick={closePopup}
                      >
                        <div className="pop-upp-img-modal"  >
                          <div className="popup-contentt-img-modal" >
                            <img
                              src={selectedImage}
                              alt="Imagem selecionada"
                            
                              onClick={(e) => e.stopPropagation()}
                            />

                          </div>
                          <button style={{ height: "40px", backgroundColor: "#4F4F4F" }} onClick={closePopup}>Fechar</button>
                        </div>
                      </div>
                    )}
                  </MessageContent>
                ))}
              <div style={{ float: 'left', clear: 'both' }} ref={messagesContainerRef} />
            </div>
          </ChatFeed>
          <ChatInput>
            <input
              type="text"
              value={currentMessage}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  sendMessage();
                }
              }}
              onChange={(e) => setCurrentMessage(e.target.value)}
            />
            <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
              <input
                id="file-upload"
                type="file"
                accept=".pdf,.rar,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <FaPaperclip
                style={{ fontSize: '45px', height: '40px', marginTop: '10px', color: 'grey' }}
              />
            </label>

            <label htmlFor='automatic-message' style={{
              fontSize: '40px', height: '40px',
              marginTop: '-1%', color: 'grey', cursor: "pointer"
            }}>
              <input
                id="automatic-message"
                style={{ display: "none" }}
              />
              <AiFillMessage onClick={openPopupmsg} />
            </label>
            {selectedmsg && (
              <div className='pop-up-container-msg' >
                <div className="pop-upp-msg">
                  <div className="popup-contentt-msg">
                    <h3>SELECIONE UMA MENSAGEM</h3>
                    <div className="message-options">
                      {messagesfinal.map((message, index) => (
                        <div key={index} className="message-option">
                          <p>{message}</p>
                          <button onClick={() => copyToClipboard(message)}>Enviar</button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {loadingFiles && (
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                <LinearProgress variant="determinate" value={uploadProgress} style={{ width: '50px' }} />
                <p style={{ marginLeft: '8px' }}>{uploadProgress}%</p>
              </div>
            )}
            <IconButton>
              <SendIcon onClick={sendMessage} />
            </IconButton>
          </ChatInput>

        </MessageContainer>

      )}
    </AdminPageContainer>
  );
}
