import {
  FaImage,
  FaFilePdf,
  FaFileArchive,
  FaFileWord,
  FaFileExcel,
  FaFile,
  FaClipboard,
  FaPaperclip,
  FaFileDownload,
} from 'react-icons/fa';
import {
  IconButton,
  Input,
  LinearProgress,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Socket } from 'socket.io-client';
import { MessageInterface } from '../../../interfaces/MessageInterface';
import { handleChatAction } from '../../../redux/actions/index.action';
import { RootState } from '../../../redux/stores';
import socket from '../../../services/socket';
import { MessageContainer } from '../../MessageContainer';
import { ChatWindowContainer } from './styles';
import { FaRegFileArchive } from "react-icons/fa";
import imageCompression from 'browser-image-compression';
import "../ChatWindow/styles.css"

interface MySocket extends Socket {
  userID: string;
}

export function ChatWindow() {
  const { user } = useSelector((state: RootState) => state.userReducer);
  const [currentMessage, setCurrentMessage] = useState('');
  const [messages, setMessages] = useState<MessageInterface[]>([]);
  const [entered, setEntered] = useState('');
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [loadingImages, setLoadingImages] = useState<boolean>(false);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadFinal, setUploadFinal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState(null);



  const dispatch = useDispatch();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const room = user.usuario;

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages]);


  useEffect(() => {
    socket.on('entered', (data) => {
      console.log(data);
      setEntered(data);
    });

    socket.on('closeCall', (data) => {
      if (data === user.usuario) {
        dispatch(handleChatAction('ended'));
      }
    });

    console.log('join_room');
    socket.emit('join_room', user.usuario);

    return () => {
      socket.off('join_room');
      socket.off('entered');
      socket.off('closeCall');
    };
  }, [dispatch, user.usuario]);

  useEffect(() => {
    // Escuta o evento 'entered' para registrar quando um usuário entra na sala
    socket.on('entered', (data) => {
      console.log(`Usuário entrou: ${data}`);
      setEntered(data); // Atualiza o estado com a mensagem recebida
    });

    // Escuta o evento 'closeCall' para finalizar o chat
    socket.on('callClosed', (data) => {
      if (data.room === user.usuario) {
        dispatch(handleChatAction('ended'));
        console.log(`Chat encerrado na sala: ${data.room}`);
      }
    });


    console.log('Entrando na sala com join_room');
    socket.emit('join_room', user.usuario);

    // Cleanup ao desmontar o componente
    return () => {
      socket.off('join_room');
      socket.off('entered');
      socket.off('callClosed');
    };
  }, [dispatch, user.usuario]);


  useEffect(() => {
    socket.on('private_message', (data) => {
      console.log(data);
      setMessages((prev) => [...prev, data]);

    });

    return () => {
      socket.off('private_message');
    };
  }, []);

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      e.target.value = '';

      setUploadProgress(0);

      try {
        setLoadingImages(true);
        const imageUrl = await uploadFile(selectedFile, (progress) => {
          setUploadProgress(progress);
        });
        setSelectedImages((prev) => [...prev, imageUrl]);
      } finally {
        setLoadingImages(false);
      }
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      e.target.value = '';
      setUploadProgress(0);

      if (!checkIfImageFile(selectedFile.type)) {
        // Verificar o tamanho do arquivo e se é uma imagem
        const maxSizeMB = 5; // Definindo o limite de 5MB
        const fileSizeMB = selectedFile.size / (1024 * 1024); // Convertendo para MB
        if (fileSizeMB > maxSizeMB) {
          // Mostrar alerta se o tamanho do arquivo exceder o limite
          alert('Por favor, envie um arquivo menor (limite de 5MB).');
          return;
        }
      }

      try {
        setLoadingFiles(true);

        let fileUrl = '';

        if (checkIfImageFile(selectedFile.type)) {
          // Comprimir a imagem se for um arquivo de imagem
          const compressedFile = await compressImage(selectedFile);
          fileUrl = await uploadFile(compressedFile, (progress) => {
            setUploadProgress(progress);
          });
          setSelectedImages((prev) => [...prev, fileUrl]);
        } else {
          // Para arquivos não relacionados a imagens, fazer upload diretamente sem compressão
          fileUrl = await uploadFile(selectedFile, (progress) => {
            setUploadProgress(progress);
          });
          setSelectedFiles((prev) => [...prev, fileUrl]);
        }
        setUploadFinal(true)
      } finally {
        setLoadingFiles(false);

      }


    }
  };



  useEffect(() => {
    if (uploadFinal) {
      sendMessage();
      setUploadFinal(false)
    }
  }, [uploadFinal]);

  // Adicione uma nova função para comprimir a imagem
  const compressImage = async (file: File): Promise<File> => {
    const options = {
      maxSizeMB: 0.7, // 700 KB
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      throw new Error('Erro ao comprimir a imagem: ');
    }
  };

  //verifica se o arquivo é imagem ,caso seja é inclusa em filestype
  const checkIfImageFile = (fileType: string): boolean => {
    const imageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    return imageTypes.includes(fileType);
  };




  const uploadFile = (file: File, onProgress: (progress: number) => void): Promise<string> => {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve(e.target?.result as string);
      };

      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        onProgress(progress);

        if (progress >= 100) {
          clearInterval(interval);
        }
      }, 500);

      setTimeout(() => {
        clearInterval(interval);
        reader.readAsDataURL(file);
      }, 2000);
    });
  };

  const sendMessage = async () => {
    if (currentMessage !== '' || selectedImages.length > 0 || selectedFiles.length > 0) {
      const messageData = {
        room,
        author: user.nome,
        message: currentMessage,
        images: selectedImages,
        files: selectedFiles,
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      socket.emit('private_message', messageData);
      setCurrentMessage('');
      setSelectedImages([]);
      setSelectedFiles([]);
      setUploadProgress(0);


    }
  };

  //verificar e converter a mensagem em um link clicavel
  const convertUrlsToLinks = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" className='links'>
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  const openPopup = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closePopup = () => {
    setSelectedImage(null);
    window.scrollTo({
      top: 1,
      behavior: 'smooth',
    });
  };

  return (
    <ChatWindowContainer>
      <div className="title-container">Suporte Mercadótica</div>
      <div className="chat-container">
        <p style={{ color: '#848081' }}>Olá {user.nome} em que podemos ajudar?</p>
        {entered !== '' && <div style={{ color: '#ccc', width: '80%', borderRadius: '30px' }}>{entered}</div>}
        {messages.map((message, index) => (
          <div key={index}>
            <MessageContainer author={message.author} message={convertUrlsToLinks(message.message)} time={message.time} />
            {message.images && message.images.map((image, imageIndex) => (

              <img
                key={imageIndex}
                src={image}
                alt={`Imagem ${imageIndex + 1}`}
                style={{
                  maxWidth: '40%',
                  maxHeight: '100px',
                  borderRadius: '8px',
                  marginTop: '8px',
                  marginLeft: message.author === user.nome ? '59%' : '1%',
                  cursor: "pointer"
                }}
                onClick={() => openPopup(image)}
              />
            ))}
            {message.files && message.files.map((file, fileIndex) => (
              <div key={fileIndex}>
                <a href={file} target="_blank" rel="noopener noreferrer" style={{ marginLeft: message.author === user.nome ? '86%' : '1%', fontSize: '45px' }}>
                  {file.endsWith('.pdf') && <FaFilePdf />}
                  {file.endsWith('.rar') && <FaFileArchive />}
                  {file.endsWith('.doc') && <FaFileWord />}
                  {file.endsWith('.docx') && <FaFileWord />}
                  {file.endsWith('.xls') && <FaFileExcel />}
                  {file.endsWith('.xlsx') && <FaFileExcel />}
                  {!file.endsWith('.pdf') && !file.endsWith('.rar') && !file.endsWith('.doc') && !file.endsWith('.docx') && !file.endsWith('.xls') && !file.endsWith('.xlsx') && <FaFileDownload />}
                </a>
              </div>
            ))}
            {selectedImage && (
              <div className='pop-up-container-img-modal'
                onClick={closePopup}
              >
                <div className="pop-upp-img-modal" >
                  <div className="popup-contentt-img-modal" >
                    <img
                      src={selectedImage}
                      alt="Imagem selecionada"
                    
                      onClick={(e) => e.stopPropagation()}
                    />

                  </div>
                  <button onClick={closePopup}>Fechar</button>
                </div>
              </div>
            )}
          </div>
        ))}
        <div style={{ float: 'left', clear: 'both' }} ref={messagesContainerRef} />
      </div>


      <div className="input-container">
        <Input
          type="text"
          placeholder="Digite aqui..."
          onChange={(e) => setCurrentMessage(e.target.value)}
          value={currentMessage}
          fullWidth
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              sendMessage();
            }
          }}
          endAdornment={
            <>
              {/*    <IconButton>
                <label htmlFor="image-upload" style={{ cursor: 'pointer' }}>
                  <input
                    id="image-upload"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />
                  <FaImage style={{ marginTop: '14px' }} />
                </label>
              </IconButton>
              {loadingImages && (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                  <LinearProgress variant="determinate" value={uploadProgress} style={{ width: '50px' }} />
                  <p style={{ marginLeft: '8px' }}>{uploadProgress}%</p>
                </div>
              )}*/}
              <IconButton>
                <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                  <input
                    id="file-upload"
                    type="file"
                    accept=".pdf,.rar,.doc,.docx,.xls,.xlsx"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <FaPaperclip style={{ marginTop: '12px' }} />
                </label>
              </IconButton>
              {loadingFiles && (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                  <LinearProgress variant="determinate" value={uploadProgress} style={{ width: '50px' }} />
                  <p style={{ marginLeft: '8px' }}>{uploadProgress}%</p>
                </div>
              )}
              <IconButton>
                <SendIcon onClick={sendMessage} />
              </IconButton>
            </>
          }
        />
      </div>
    </ChatWindowContainer>
  );
}
